<template>
  <div>
    <div class="container bg-white p-4">
      <div class="row text-center my-5" v-if="busy">
        <div class="col">
          <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
        </div>
      </div>
      <div v-else>
        <div class="row mb-2">
          <div class="col">
            <h5>My Resources</h5>
          </div>
          <div class="d-none d-sm-flex">
            <div class="col-auto ml-auto">
              <div class="custom-control custom-switch">
                <input v-model="showRecent" type="checkbox" class="custom-control-input" id="customSwitches">
                <label class="custom-control-label" for="customSwitches">Show Recent</label>
              </div>
            </div>
            <div class="col-auto ml-auto">
              <button
                class="btn btn-sm btn-outline-dark mr-2"
                :class="sortDirection == 'asc' ? 'active' : ''"
                @click="sortDirection = 'asc'"
              >
                <i class="fad fa-sort-size-up"></i>
              </button>
              <button
                class="btn btn-sm btn-outline-dark"
                :class="sortDirection == 'desc' ? 'active' : ''"
                @click="sortDirection = 'desc'"
              >
                <i class="fad fa-sort-size-down"></i>
              </button>
            </div>
            <div class="col-auto">
              <div class="dropdown">
                <button
                  class="btn btn-light btn-sm dropdown-toggle"
                  type="button"
                  id="sortingDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fad fa-sort mr-2"></i>
                  <span class="text-capitalize">{{
                    sorting | formatLabel
                  }}</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="sortingDropdown">
                  <button
                    class="dropdown-item"
                    type="button"
                    @click="sorting = 'name'"
                  >
                    Name
                  </button>
                  <button
                    class="dropdown-item"
                    type="button"
                    @click="sorting = 'created_at'"
                  >
                    Date Created
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-5 align-items-center mr-0">
            <input
              type="text"
              v-model="search"
              class="form-control form-control-sm"
              autocomplete="offington"
              placeholder="Search by name or URL"
            />
          </div>
        </div>

        <div class="row mb-4" v-if="showRecent && recentResources.length > 0">
          <div class="col">
            <h5 class="mb-3">Recent ({{ recentResources.length }})</h5>
            <div class="d-flex resource-row" style="overflow-x: scroll">
              <div
                class="col-lg-2 col-md-3 col-sm-4 mb-3"
                v-for="clientResource in recentResources"
                :key="clientResource.id"
              >
                <div class="card border-0">
                  <span
                    class="badge badge-danger badge-top-right"
                    v-if="!clientResource.read_by_client"
                    >New</span
                  >
                  <span
                    class="badge badge-danger badge-top-right"
                    v-if="
                      clientResource.read_by_client &&
                      clientResource.comments_unread_by_client.length > 0
                    "
                    >{{ clientResource.comments_unread_by_client.length }}</span
                  >
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center">
                        <h6 class="mb-0 text-primary wrap-ellipses">
                          {{ clientResource.resource.title }}
                        </h6>
                        <p
                          v-if="clientResource.resource.model === 'UrlResource'"
                          class="mb-0 bg-light mt-3 p-2"
                        >
                          <i class="fas fa-link" style="font-size: 36px"></i>
                        </p>
                        <p
                          v-if="
                            clientResource.resource.model === 'FileResource'
                          "
                          class="mb-0 bg-light mt-3 p-2"
                        >
                          <i class="fas fa-file" style="font-size: 36px"></i>
                        </p>
                        <p
                          v-if="
                            clientResource.resource.model === 'LegalResource'
                          "
                          class="mb-0 bg-light mt-3 p-2"
                        >
                          <i
                            class="fas fa-file-contract"
                            style="font-size: 36px"
                          ></i>
                        </p>
                        <a
                          :href="'/resources/' + clientResource.id"
                          class="btn btn-sm btn-outline-primary mt-3 w-100"
                          ><i class="fa fa-eye mr-2"></i>View</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <div class="row">
              <div class="col-auto ml-auto mb-1" v-if="folderOpen">
            <span class="mr-2">Current folder: {{ folderOpen.resource.title }}</span>
            <button
              class="btn btn-outline-primary"
              @click="upLevel()"
            >
              <i class="fad fa-level-up-alt"></i>
            </button>
          </div>
            </div>
            <div
              class="row resource-row"
              style="overflow-y: auto; max-height: 300px"
            >
              <div
                class="col-lg-2 col-md-3 col-sm-4 mb-3"
                v-for="c in currentFolders()"
                :key="c.id">
                <div class="card border-0">
                  <span
                    class="badge badge-danger badge-top-right"
                    v-if="!c.read_by_client"
                    >New</span
                  >
                  <span
                    class="badge badge-danger badge-top-right"
                    v-if="
                      c.read_by_client &&
                      c.comments_unread_by_client.length > 0
                    "
                    >{{ c.comments_unread_by_client.length }}</span
                  >
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center">
                        <h6 class="mb-0 text-primary wrap-ellipses">
                          {{ c.resource.title }}
                        </h6>
                        <p class="mb-0 bg-light mt-3 p-2">
                          <i class="fas fa-folder" style="font-size: 36px"></i>
                        </p>
                        <a
                          @click="openFolder(c)"
                          class="btn btn-sm btn-outline-primary mt-3 w-100"
                          ><i class="fa fa-folder-open mr-2"></i>Open</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-2 col-md-3 col-sm-4 mb-3"
                v-for="c in currentResources()"
                :key="c.id">
                <div class="card border-0">
                  <span
                    class="badge badge-danger badge-top-right"
                    v-if="!c.read_by_client"
                    >New</span
                  >
                  <span
                    class="badge badge-danger badge-top-right"
                    v-if="
                      c.read_by_client &&
                      c.comments_unread_by_client.length > 0
                    "
                    >{{ c.comments_unread_by_client.length }}</span
                  >
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center">
                        <h6 class="mb-0 text-primary wrap-ellipses">
                          {{ c.resource.title }}
                        </h6>
                        <p class="mb-0 bg-light mt-3 p-2">
                          <i class="fas fa-file" style="font-size: 36px"></i>
                        </p>
                        <a
                          :href="'/resources/' + c.id"
                          class="btn btn-sm btn-outline-primary mt-3 w-100"
                          ><i class="fa fa-eye mr-2"></i>View</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="resources.length === 0 || !resources">
          <div class="col">No results.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["client"],
  data() {
    return {
      showRecent: false,
      folderOpen: null,
      resources: [],
      sortDirection: "desc",
      sorting: "created_at",
      search: "",
      busy: true,
    };
  },
  methods: {
    openFolder(c) {
      this.folderOpen = c
      this.markResourceAsReadByClient(c.id)
    },
    markResourceAsReadByClient(client_resource_id) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/resources/mark-read-by-client", {
            client_resource_id,
        })
        .then(() => {
          this.folderResources.map((folder, index) => {
            if (folder.id === client_resource_id) {
              this.folderResources[index]['read_by_client'] = 1;
            }
          })
        });
    },
    upLevel() {
      this.$nextTick(() => {
        if (this.folderOpen.folder) {
          this.folderOpen = this.resources.find((res) => res.id === this.folderOpen.client_folder);
        } else {
          this.folderOpen = null;
        }
      });
    },
    currentFolders() {
      const vm = this;
      if (this.folderOpen) {
        return this.folderResources.filter(function (el) {
          return el.client_folder === vm.folderOpen.id;
        });
      } else {
        return this.folderResources.filter(function (el) {
          return el.client_folder === 0;
        });
      }
    },
    currentResources() {
      let folder = this.folderOpen;
      if (folder) {
        return this.resources.filter(function (res) {
          return (res.resource && res.resource.model !== "FolderResource" && res.client_folder === folder.id);
        });
      }
      else {
        return this.resources.filter(function (res) {
            return (res.resource && res.resource.model !== "FolderResource" && res.client_folder == 0);
        });
      }
    },
    sortBy(data) {
      let vm = this;
      if (vm.sorting === "created_at") {
        data.sort(function (a, b) {
          if (vm.sortDirection === "desc") {
            return a.created_at - b.created_at;
          } else {
            return b.created_at - a.created_at;
          }
        });
      } else if (vm.sorting === "name") {
        data.sort();
        if (vm.sortDirection === "desc") {
          data.reverse();
        }
      }

      return data;
    },
    clearSearch() {
      this.search = "";
    },
    searchResources(data) {
      if (this.search && this.search.length > 0) {
        return data.filter((item) => {
          return (
            item.resource.title
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          );
        });
      }
      return data;
    },
    fetchClientResources() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/resources")
        .then(({ data }) => {
          this.resources = data.resources;
          this.busy = false;
        });
    },
  },
  mounted() {
    this.fetchClientResources();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
    formatLabel(str) {
      if (!str) {
        return "Sorting";
      } else {
        return str.replace(/_/g, " ");
      }
    },
    truncate(text) {
      if (text != null) {
        if (text.length > 250) {
          return text.substring(0, 250) + "...";
        } else if (text.length == 0) {
          return "No description provided";
        } else {
          return text;
        }
      } else {
        return "No description provided";
      }
    },
  },
  computed: {
    recentResources: function () {
      return this.sortBy(
        this.searchResources(
          this.resources
          .filter((el) => el.resource?.model !== "FolderResource")
            .sort((a, b) => (a.created_by > b.created_by ? 1 : -1))
            .slice(0, 6)
        )
      );
    },
    folderResources: function () {
      return this.resources.filter((el) => el.resource && el.resource.model === "FolderResource");
    },
  },
};
</script>

<style>
</style>
